import React, { useState, useEffect, useMemo } from "react";
import DataTable from "react-data-table-component";
import "./donor.css";
import { useMutation, useQuery, useQueryClient } from "react-query";
import useUtil from "../../hooks/useUtil";
import { toast } from "react-toastify";
import { Form, Modal, Input, DatePicker, message, Button, Table } from "antd";
import { useUpdateDonation } from "../../hooks/useUpdateDonation";

const AllDonor = () => {
  const {
    getAllDonorListReqeust,
    getAllDonorListReqeustLoadMore,
    blockHandlerRequest,
  } = useUtil();
  // BOdy Ref
  const queryClient = useQueryClient();
  const [totalDoc, setTotalDoc] = useState(0);

  const [text, setText] = useState("");
  const [sortBy, setSortBy] = useState("group");

  const { data: donorData, isLoading: initialLoading } = useQuery(
    ["donor-list", text, sortBy],
    () => getAllDonorListReqeust({ skip: 0, text, sortBy }),
    {
      refetchOnWindowFocus: false,
    }
  );

  const [data, setData] = useState([]);

  useEffect(() => {
    if (donorData !== undefined) {
      setData(donorData?.users);
      setTotalDoc(donorData?.count);
    }
  }, [donorData]);

  const { mutate: loadMoreAction, isLoading } = useMutation(
    getAllDonorListReqeustLoadMore,
    {
      onSuccess: (res) => {
        setData((p) => {
          return [...p, ...res];
        });
      },
    }
  );

  useEffect(() => {
    if (isLoading) {
      window.scrollTo(1000000, 1000000);
    }
  }, [isLoading]);

  const { mutate: blockHandlerAction } = useMutation(blockHandlerRequest, {
    onSuccess: () => {
      queryClient.invalidateQueries("donor-list");
      toast.success("operation successfull", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    },
    onError: (err) => {
      toast.error(err.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    },
  });

  function blockHandler({ isBan, id }) {
    blockHandlerAction({ isBan, id });
  }

  // Donor update from admin
  const [donorUpdateModal, setDonorUpdateModal] = useState(false);
  // Donation Update
  const [donation, setDonation] = useState({
    _date: null,
    pack: 1,
    id: null,
  });

  const columns = [
    {
      title: "Serial",
      dataIndex: "_id",
      key: "_id",
      render: (a, b, c) => c + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Blood",
      dataIndex: "blood",
      key: "blood",
    },
    {
      title: "District",
      dataIndex: "district",
      key: "district",
    },
    {
      title: "Thana",
      dataIndex: "upuzila",
      key: "upuzila",
    },
    {
      title: "Thana",
      dataIndex: "upuzila",
      key: "upuzila",
      render: (phone, row) => (
        <>
          <span style={{ background: "#efefef", padding: "1px 2px" }}>
            <a href={`tel:${row.phone}`}>{row.phone}</a>
          </span>{" "}
          <br /> <a href={`tel:${row.mobile1}`}>{row.mobile1}</a>
          <br /> <a href={`tel:${row.mobile2}`}>{row.mobile2}</a>
        </>
      ),
    },
    {
      title: "Facebook",
      dataIndex: "facebook",
      key: "facebook",
      render: (f, row) => (
        <a target='_blank' href={row.facebook} rel='noreferrer'>
          {" "}
          Link{" "}
        </a>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Address",
      dataIndex: "presentAddress",
      key: "presentAddress",
      render: (presentAddress, row) => (
        <>
          {row.presentAddress} <br /> {row.parmanentAddress}{" "}
        </>
      ),
    },

    {
      title: "Availibity",
      dataIndex: "available",
      key: "available",
      render: (av) => (av ? "Public" : "Private"),
    },
    {
      title: "Availibity",
      dataIndex: "lastDonation",
      key: "lastDonation",
      render: (lastDonation, row) => (
        <>
          {row.lastDonation && (
            <>
              {new Date(row.lastDonation).getDate()}/
              {new Date(row.lastDonation).getMonth() + 1}/
              {new Date(row.lastDonation).getFullYear()}
            </>
          )}
        </>
      ),
    },

    {
      title: "Action",
      dataIndex: "_id",
      key: "_id",
      render: (id, row) => (
        <>
          {row.isBan ? (
            <button
              onClick={() => blockHandler({ isBan: false, id: row._id })}
              className='btn'
            >
              un-block
            </button>
          ) : (
            <button
              onClick={() => blockHandler({ isBan: true, id: row._id })}
              className='btn'
            >
              block
            </button>
          )}
          <br />

          <button
            className='btn'
            style={{ marginTop: 5 }}
            onClick={(_) => {
              setDonorUpdateModal(true);
              setDonation((p) => ({ ...p, id: row?._id }));
            }}
          >
            donation update
          </button>
          <br />
        </>
      ),
    },
  ];

  //   {
  //     width: "50px",
  //     name: "SL",
  //     selector: (row, index) => <>{index + 1}</>,
  //   },
  //   {
  //     width: "150px",
  //     name: "Donor Name",
  //     selector: (row) => row.name,
  //   },
  //   {
  //     width: "70px",
  //     name: "Blood Group",
  //     selector: (row) => row.blood,
  //   },
  //   {
  //     name: "District",
  //     selector: (row) => row.district,
  //   },
  //   {
  //     name: "Phone",
  //     selector: (row) => (
  // <>
  //   {" "}
  //   <span style={{ background: "#efefef", padding: "1px 2px" }}>
  //     <a href={`tel:${row.phone}`}>{row.phone}</a>
  //   </span>{" "}
  //   <br /> <a href={`tel:${row.mobile1}`}>{row.mobile1}</a>
  //   <br /> <a href={`tel:${row.mobile2}`}>{row.mobile2}</a>
  // </>
  //     ),
  //   },
  //   {
  //     name: "Email",
  //     width: "200px",
  //     selector: (row) => <span> {row.email} </span>,
  //   },
  //   {
  //     name: "facebook",
  //     width: "100px",
  //     selector: (row) => (
  // <a target='_blank' href={row.facebook} rel='noreferrer'>
  //   {" "}
  //   Link{" "}
  // </a>
  //     ),
  //   },
  //   {
  //     name: "Address",
  //     width: "300px",
  //     selector: (row) => (
  // <>
  //   {" "}
  //   {row.presentAddress} <br /> {row.parmanentAddress}{" "}
  // </>
  //     ),
  //   },
  //   {
  //     name: "Availibility",
  //     selector: (row) => (row.available ? "Public" : "Private"),
  //   },
  //   {
  //     name: "Last Donation",
  //     selector: (row) => (
  // <>
  //   {row.lastDonation && (
  //     <>
  //       {new Date(row.lastDonation).getDate()}/
  //       {new Date(row.lastDonation).getMonth() + 1}/
  //       {new Date(row.lastDonation).getFullYear()}
  //     </>
  //   )}
  // </>
  //     ),
  //   },
  //   {
  //     name: "Action",
  //     selector: (row) => (
  //       <>
  //         {row.isBan ? (
  //           <button
  //             onClick={() => blockHandler({ isBan: false, id: row._id })}
  //             className='btn'
  //           >
  //             un-block
  //           </button>
  //         ) : (
  //           <button
  //             onClick={() => blockHandler({ isBan: true, id: row._id })}
  //             className='btn'
  //           >
  //             block
  //           </button>
  //         )}
  //         <br />

  //         <button
  //           className='btn'
  //           style={{ marginTop: 5 }}
  //           onClick={(_) => {
  //             setDonorUpdateModal(true);
  //             setDonation((p) => ({ ...p, id: row?._id }));
  //           }}
  //         >
  //           donation update
  //         </button>
  //         <br />
  //       </>
  //     ),
  //   },
  // ];

  //setup before functions
  let typingTimer; //timer identifier
  let doneTypingInterval = 700; //time in ms (1 seconds)
  function doneTyping(val) {
    //do something
    setText(val);
  }

  // Update DOnation
  const { mutate, isLoading: updateLoading, status } = useUpdateDonation();
  function updateDonationHandler() {
    if (donation.id === "") {
      message.error("Select Donor Again");
      return;
    }

    if (donation.pack === "") {
      message.error("Minimum pack is one");
      return;
    }

    if (donation._date === "") {
      message.error("Select Donation Date");
      return;
    }

    mutate(donation);
  }

  useMemo(() => {
    if (status === "success") {
      setDonorUpdateModal(false);
      queryClient.invalidateQueries(["donor-list", text]);
    }
  }, [status, text, queryClient]);

  //  Table is here

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 20,
        }}
      >
        <div>
          <Button
            type={sortBy === "group" ? "primary" : "default"}
            onClick={(_) => setSortBy("group")}
            size='small'
          >
            Blood Group
          </Button>
          <Button
            type={sortBy === "donated" ? "primary" : "default"}
            onClick={(_) => setSortBy("donated")}
            size='small'
          >
            Donated Persion
          </Button>
          <Button
            type={sortBy === "none-donated" ? "primary" : "default"}
            onClick={(_) => setSortBy("none-donated")}
            size='small'
          >
            Non Donated Persion
          </Button>
        </div>
        <Input
          onChange={(e) => {
            clearTimeout(typingTimer);
            typingTimer = setTimeout(
              () => doneTyping(e.target.value),
              doneTypingInterval
            );
          }}
          style={{ maxWidth: 200 }}
          placeholder='search'
        />
      </div>
      <div className='tableArea'>
        <div style={{ width: "100%", overflow: "scroll" }}>
          <Table
            size='small'
            rowKey={"_id"}
            dataSource={data}
            columns={columns}
            pagination={false}
          />
        </div>

        {totalDoc !== data.length && (
          <div style={{ textAlign: "center" }}>
            {isLoading ? (
              <img height={50} src='./loading.gif' alt='Loading' />
            ) : (
              <button
                style={{
                  marginBottom: 100,
                  marginTop: 50,
                  background: "#e2414c",
                  padding: "5px 10px",
                  color: "#fff",
                }}
                className='btn'
                onClick={() => {
                  loadMoreAction(data.length);
                }}
              >
                Load More
              </button>
            )}
          </div>
        )}
      </div>

      <Modal
        centered
        title='Update Donation'
        visible={donorUpdateModal}
        onOk={updateDonationHandler}
        okText='Update'
        onCancel={() => {
          setDonorUpdateModal(false);
        }}
        okButtonProps={{ loading: updateLoading }}
      >
        <Form layout='vertical'>
          <Form.Item label='Date'>
            <DatePicker
              onChange={(e) => {
                setDonation((p) => ({ ...p, _date: e?._d }));
              }}
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item label='How Many Beg Donated'>
            <Input
              type='number'
              placeholder='1 Beg'
              value={donation.pack}
              style={{ width: "100%" }}
              onChange={(e) => {
                setDonation((p) => ({ ...p, pack: e.target.value }));
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AllDonor;
