import { useAxios } from "./../lib/AxiosProvider";
const useUtil = () => {
	const axios = useAxios();

	const getAllDonorListReqeust = async (skip) => {
		const { data } = await axios.get("/admin/donor-list", {
			params: {
				...skip,
			},
		});
		return data;
	};

	const getAllDonorListReqeustLoadMore = async (skip) => {
		const { data } = await axios.get("/admin/donor-list-load-more", {
			params: {
				skip,
			},
		});
		return data;
	};

	const saveGalaryPhotoRequest = async (payload) => {
		const { data } = await axios.post("/admin/save-gallary-photo", payload);
		return data;
	};

	

	const getInitialPhotos = async () => {
		const { data } = await axios.get("/admin/initial-photos");
		return data;
	};
	const loadMorePhotos = async (skip) => {
		const { data } = await axios.get("/admin/load-more-photos", {
			params: {
				skip,
			},
		});
		return data;
	};

	const blockHandlerRequest = async (payload) => {
		const { data } = await axios.post("/admin/user-block", payload);
		return data;
	};

	

	return {
		getAllDonorListReqeust,
		getAllDonorListReqeustLoadMore,
		saveGalaryPhotoRequest,
		getInitialPhotos,
		loadMorePhotos,
		blockHandlerRequest
	};
};

export default useUtil;
