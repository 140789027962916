import React, { useRef, useState, useEffect } from "react";
import "../donorList/donor.css";
import "./galary.css";

import { useAxios } from "./../../lib/AxiosProvider";
import { useQuery, useMutation, useQueryClient } from "react-query";
import useUtil from "../../hooks/useUtil";
import "photoswipe/dist/photoswipe.css";
import "photoswipe/dist/default-skin/default-skin.css";
import { confirmAlert } from 'react-confirm-alert'; // Import


const Gallary = () => {
	const [totalPhotoCount, setTotalPhotoCount] = useState(0);
	const [photos, setPhotos] = useState([]);
	const queryClient = useQueryClient();

	const { saveGalaryPhotoRequest, getInitialPhotos, loadMorePhotos } =
		useUtil();

	const { data: initialData, isLoading: initialLoading } = useQuery(
		"initial-photos",
		getInitialPhotos,
		{
			refetchOnWindowFocus: false,
		}
	);

	useEffect(() => {
		if (initialData !== undefined) {
			setPhotos(initialData?.photos);
			setTotalPhotoCount(initialData?.count);
		}
	}, [initialData]);

	
	const fileRef = useRef();
	const axios = useAxios();
	const [uploading, setUploading] = useState(false);

	const { mutate: savePhotoAction } = useMutation(saveGalaryPhotoRequest, {
		onSuccess: () => {
			queryClient.invalidateQueries("initial-photos");
		},
	});

	async function uploadPhoto(event) {
		const files = event.target.files;
		if (files.length > 0) {
			try {
				setUploading(true);
				let formData = new FormData();
				formData.append("file", files[0]);
				const { data } = await axios.post("image-upload", formData);
				setUploading(false);
				savePhotoAction({ photo: data?.url });
			} catch (error) {
				console.log(error);
			}
		}
	}

	function choosePhotoUploadHandler() {
		fileRef.current.click();
	}

	const { mutate: loadMoreAction } = useMutation(loadMorePhotos, {
		onSuccess: (res) => {
			setPhotos((p) => {
				return [...p, ...res];
			});
		},
	});


	async function deleteRequest({ id }){
		const { data } = await axios.delete('/admin/delete-galary-photo', {
			params:{
				id
			}
		});
		return data;
	}

	const { mutate:photoDeleteAction } =  useMutation(deleteRequest, {
		onSuccess:(res) => {
			setPhotos(p => {
				return p.filter(x => String(x._id) !== String(res))
			})
		},
		onError:() => {
			console.log('Error')
		}
	});
	
	function onConfirm({ id }){
		confirmAlert({
		title: 'Confirm to Delete ?',
		message: 'This Photo will be deleted permanently ',
		buttons: [
			{
			label: 'Yes',
			onClick: () => photoDeleteAction({ id })
			},
			{
			label: 'No',
			onClick: () => {
				//
			}
			}
		]
		});
	}

	
	return (
		<>
			<div>
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<button
						style={{
							height: 40,
							width: 150,
							margin: 10,
							backgroundColor: "#4d4c4c",
							border: "none",
							color: "#fff",
							borderRadius: 5,
							cursor: "pointer",
						}}
						onClick={choosePhotoUploadHandler}
					>
						Add New Photo
					</button>
					<div
						style={{
							flex: 1,
							display: "flex",
							justifyContent: "center",
						}}
					>
						{uploading && <span>Uploading...</span>}
					</div>
				</div>
				<input
					ref={fileRef}
					onChange={(event) => {
						uploadPhoto(event);
					}}
					type="file"
					hidden
				/>
			</div>
			<div className="tableArea">
				{initialLoading ? (
					<h1>Loading...</h1>
				) : (
					<div className="__gallary_photo_wrapper">
						{photos.map(x => 
							<div className="__photo_card" key={x._key}>
								<img className="__gallary_photo" src={x.photo} alt="" />
								<div className="__galary_action">
									{/* <button>Edit</button>  */}
									<button onClick={() => onConfirm({ id:x._id })}>Delete</button>
								</div>
							</div>
						)}

					</div>
				)}
			</div>

			{totalPhotoCount !== photos.length && (
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<button
						style={{
							border: "none",
							// cursor:"pointer"
						}}
						onClick={() => {
							loadMoreAction(photos.length);
						}}
					>
						Load more
					</button>
				</div>
			)}
		</>
	);
};

export default Gallary;
