import React, { useState } from "react";
import { Form, Input, Button, Alert } from "antd";
import useUser from "./../../hooks/useUser";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import { useMutation } from "react-query";

const Login = ({ setUser }) => {
  const { LoginRequest } = useUser();

  const [msg, setMsg] = useState({
    show: false,
    msg: "",
    type: "",
  });

  // auth state
  const [state, setState] = useState({
    username: "",
    password: "",
  });

  const { mutate: loginAction, isLoading } = useMutation(LoginRequest, {
    onSuccess: (res) => {
      localStorage.setItem("blood-token", res.token);
      setUser(res);
      setMsg({
        show: true,
        msg: "Login Successfull",
        type: "danger",
      });
    },
    onError: (err) => {
      setMsg({
        show: true,
        msg: err?.response?.data?.message,
        type: "danger",
      });
    },
  });

  console.log(msg);

  // auth Handler
  function loginHandler() {
    const { username, password } = state;
    if (username === "") {
      toast.error("Username ?", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (password === "") {
      toast.error("Password ?", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      loginAction(state);
    }
  }

  return (
    <div className='login_form'>
      <Form
        name='normal_login'
        className='login-form'
        initialValues={{ remember: true }}
        onFinish={loginHandler}
      >
        {msg.show && <Alert message={msg.msg} type='error' showIcon />}

        <Form.Item
          name='username'
          rules={[{ required: true, message: "Please input your Username!" }]}
        >
          <Input
            prefix={<UserOutlined className='site-form-item-icon' />}
            placeholder='Username'
            onChange={(e) =>
              setState((p) => ({ ...p, username: e.target.value }))
            }
          />
        </Form.Item>
        <Form.Item
          name='password'
          rules={[{ required: true, message: "Please input your Password!" }]}
        >
          <Input
            prefix={<LockOutlined className='site-form-item-icon' />}
            type='password'
            placeholder='Password'
            onChange={(e) =>
              setState((p) => ({ ...p, password: e.target.value }))
            }
          />
        </Form.Item>

        <Form.Item>
          <Button
            type='primary'
            htmlType='submit'
            className='login-form-button'
            onClick={loginHandler}
            loading={isLoading}
          >
            Log in
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Login;
