import { useAxios } from "./../lib/AxiosProvider";
const useUtil = () => {
	const axios = useAxios();

	const LoginRequest = async (payload) => {
		const { data } = await axios.post("/admin/login", payload);
		return data;
	};
	const tokenvalidate = async (payload) => {
		const { data } = await axios.post("/admin/validate", payload);
		return data;
	};

	return {
		LoginRequest,
		tokenvalidate
	};
};

export default useUtil;
