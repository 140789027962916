import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Nav from "./components/nav/Nav";
import Home from "./screens/home/Home";
import AllDonor from "./screens/donorList/AllDonor";
import Gallary from "./screens/gallary/Gallary";
import Others from "./screens/others/Others";
import useUser from "./hooks/useUser";
import { useMutation } from "react-query";

import Login from "./screens/login/Login";
import PocketMenu from "./components/nav/pocket-menu/PocketMenu";
function App() {
  const [user, setUser] = useState(null);
  const { tokenvalidate } = useUser();

  // mutation

  const { mutate: validationToken } = useMutation(tokenvalidate, {
    onSuccess: (res) => {
      localStorage.setItem("blood-token", res.token);
      setUser(res);
    },
    onError: (err) => {
      //
    },
  });

  // check refresh token
  useEffect(() => {
    const token = localStorage.getItem("blood-token");
    if (token !== null) {
      validationToken({ token });
    }
    // eslint-disable-next-line
  }, []);

  //

  return (
    <>
      <Router>
        <div className='a'>
          {user === null ? (
            <>
              <Login setUser={setUser} />
            </>
          ) : (
            <>
              <Nav setUser={setUser} />
              <div className='content'>
                <PocketMenu />
                <Switch>
                  <Route path='/' exact component={Home} />
                  <Route path='/allDonor' exact component={AllDonor} />
                  <Route path='/gallary' exact component={Gallary} />
                  <Route path='/others' exact component={Others} />
                </Switch>
              </div>
            </>
          )}
        </div>
      </Router>
    </>
  );
}

export default App;
