import React from "react";

const Others = () => {
  return (
    <div>
      <span>this is the other page</span>
    </div>
  );
};

export default Others;
