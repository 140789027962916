import { useAxios } from "./../lib/AxiosProvider";
import { useMutation } from "react-query";
import { message } from "antd";
export const useUpdateDonation = () => {
  const axios = useAxios();
  return useMutation(
    async (payload) => {
      const { data } = await axios.post("/user/donate-from-admin", payload);
      return data;
    },
    {
      onSuccess: () => {
        console.log("success");
        message.success("Donation Successfull");
      },
      onError: (err) => {
        message.error(err?.response?.data?.message);
      },
    }
  );
};
