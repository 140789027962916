import React from "react";
import "./home.css";
import useDashboard from "./../../hooks/useDashboard";
import { useQuery } from "react-query";

import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
const Home = () => {
	const {
		donorByBloodGroupReqeust,
		totalDonorCountRequest,
		totalBagDonatedCountRequest,
		totalBagRequestedCountRequest,
		totalCountRequest,
	} = useDashboard();
	const { data: totalDonor, isLoading: totalDonorLoading } = useQuery(
		"total-donor",
		totalDonorCountRequest,
		{
			refetchOnWindowFocus: false,
		}
	);
	const { data: totalBagDonated, isLoading: totalBagDonatedLoading } = useQuery(
		"total-beg-donated",
		totalBagDonatedCountRequest,
		{
			refetchOnWindowFocus: false,
		}
	);
	const { data: totalBagRequested, isLoading: totalBegRequestLoading } =
		useQuery("total-beg-requested", totalBagRequestedCountRequest, {
			refetchOnWindowFocus: false,
		});

	const {
		data: totalPatientRequested,
		isLoading: totalPatientRequestedLoading,
	} = useQuery("total-patient-requested", totalCountRequest, {
		refetchOnWindowFocus: false,
	});

	const { data: donorAPlus, isLoading: aPlusLoading } = useQuery(
		"donor-a+",
		() => donorByBloodGroupReqeust("a+"),
		{
			refetchOnWindowFocus: false,
		}
	);

	const { data: donorBPlus, isLoading: bPlusLoading } = useQuery(
		"donor-b+",
		() => donorByBloodGroupReqeust("b+"),
		{
			refetchOnWindowFocus: false,
		}
	);

	const { data: donorOPlus, isLoading: oPlusLoading } = useQuery(
		"donor-o+",
		() => donorByBloodGroupReqeust("o+"),
		{
			refetchOnWindowFocus: false,
		}
	);

	const { data: donorABPlus, isLoading: abPlusLoading } = useQuery(
		"donor-ab+",
		() => donorByBloodGroupReqeust("ab+"),
		{
			refetchOnWindowFocus: false,
		}
	);

	const { data: donorAMinus, isLoading: aMinusLoading } = useQuery(
		"donor-a-",
		() => donorByBloodGroupReqeust("a-"),
		{
			refetchOnWindowFocus: false,
		}
	);

	const { data: donorBMinus, isLoading: bMinusLoading } = useQuery(
		"donor-b-",
		() => donorByBloodGroupReqeust("b-")
	);

	const { data: donorOMinus, isLoading: oMinusLoading } = useQuery(
		"donor-o-",
		() => donorByBloodGroupReqeust("o-")
	);

	const { data: donorABMinus, isLoading: abMinusLoading } = useQuery(
		"donor-ab-",
		() => donorByBloodGroupReqeust("ab-")
	);

	return (
		<>
			<div className="statisticsArea">
				<div className="statCardArea">
					{totalDonorLoading ? (
						<SkeletonTheme baseColor="#ffffff" highlightColor="#dedede">
							<Skeleton className="statCard" />
						</SkeletonTheme>
					) : (
						<div className="statCard">
							<span className="statTitle">Total Donor</span>
							<span className="amount">{totalDonor}</span>
						</div>
					)}

					{totalBagDonatedLoading ? (
						<SkeletonTheme baseColor="#ffffff" highlightColor="#dedede">
							<Skeleton className="statCard" />
						</SkeletonTheme>
					) : (
						<div className="statCard">
							<span className="statTitle">Total Bag Donated</span>
							<span className="amount">{totalBagDonated}</span>
						</div>
					)}

					{totalPatientRequestedLoading ? (
						<SkeletonTheme baseColor="#ffffff" highlightColor="#dedede">
							<Skeleton className="statCard" />
						</SkeletonTheme>
					) : (
						<div className="statCard">
							<span className="statTitle">Blood Patients</span>
							<span className="amount">{totalPatientRequested}</span>
						</div>
					)}

					{totalBegRequestLoading ? (
						<SkeletonTheme baseColor="#ffffff" highlightColor="#dedede">
							<Skeleton className="statCard" />
						</SkeletonTheme>
					) : (
						<div className="statCard">
							<span className="statTitle">Total Bag Requested</span>
							<span className="amount">{totalBagRequested}</span>
						</div>
					)}

					{aPlusLoading ? (
						<SkeletonTheme baseColor="#ffffff" highlightColor="#dedede">
							<Skeleton className="statCard" />
						</SkeletonTheme>
					) : (
						<div className="statCard">
							<span className="statTitle">A+ Donor</span>
							<span className="amount">{donorAPlus}</span>
						</div>
					)}

					{bPlusLoading ? (
						<SkeletonTheme baseColor="#ffffff" highlightColor="#dedede">
							<Skeleton className="statCard" />
						</SkeletonTheme>
					) : (
						<div className="statCard">
							<span className="statTitle">B+ Donor</span>
							<span className="amount">{donorBPlus}</span>
						</div>
					)}

					{oPlusLoading ? (
						<SkeletonTheme baseColor="#ffffff" highlightColor="#dedede">
							<Skeleton className="statCard" />
						</SkeletonTheme>
					) : (
						<div className="statCard">
							<span className="statTitle">O+ Donor</span>
							<span className="amount">{donorOPlus}</span>
						</div>
					)}

					{abPlusLoading ? (
						<SkeletonTheme baseColor="#ffffff" highlightColor="#dedede">
							<Skeleton className="statCard" />
						</SkeletonTheme>
					) : (
						<div className="statCard">
							<span className="statTitle">AB+ Donor</span>
							<span className="amount">{donorABPlus}</span>
						</div>
					)}

					{aMinusLoading ? (
						<SkeletonTheme baseColor="#ffffff" highlightColor="#dedede">
							<Skeleton className="statCard" />
						</SkeletonTheme>
					) : (
						<div className="statCard">
							<span className="statTitle">A- Donor</span>
							<span className="amount">{donorAMinus}</span>
						</div>
					)}

					{bMinusLoading ? (
						<SkeletonTheme baseColor="#ffffff" highlightColor="#dedede">
							<Skeleton className="statCard" />
						</SkeletonTheme>
					) : (
						<div className="statCard">
							<span className="statTitle">B- Donor</span>
							<span className="amount">{donorBMinus}</span>
						</div>
					)}

					{oMinusLoading ? (
						<SkeletonTheme baseColor="#ffffff" highlightColor="#dedede">
							<Skeleton className="statCard" />
						</SkeletonTheme>
					) : (
						<div className="statCard">
							<span className="statTitle">O- Donor</span>
							<span className="amount">{donorOMinus}</span>
						</div>
					)}

					{abMinusLoading ? (
						<SkeletonTheme baseColor="#ffffff" highlightColor="#dedede">
							<Skeleton className="statCard" />
						</SkeletonTheme>
					) : (
						<div className="statCard">
							<span className="statTitle">AB- Donor</span>
							<span className="amount">{donorABMinus}</span>
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default Home;
