import { useAxios } from "./../lib/AxiosProvider";
const useDashboard = () => {
	const axios = useAxios();

	const donorByBloodGroupReqeust = async (blood) => {
		const { data } = await axios.get("/admin/donor-by-group", {
			params: {
				blood,
			},
		});
		return data;
	};

	const totalDonorCountRequest = async (blood) => {
		const { data } = await axios.get("/admin/total-donor");
		return data;
	};

	const totalBagDonatedCountRequest = async (blood) => {
		const { data } = await axios.get("/admin/total-beg-donated");
		return data;
	};

	const totalBagRequestedCountRequest = async (blood) => {
		const { data } = await axios.get("/admin/total-beg-requested");
		return data;
	};

	const totalCountRequest = async (blood) => {
		const { data } = await axios.get("/admin/total-requested");
		return data;
	};

	return {
		donorByBloodGroupReqeust,
		totalDonorCountRequest,
		totalBagDonatedCountRequest,
		totalBagRequestedCountRequest,
		totalCountRequest,
	};
};

export default useDashboard;
