import React, { useState } from "react";
import { Form, Input, Modal, Select } from "antd";
import useData from "../../../datas/useData";

const { Option } = Select;

const PocketMenu = () => {
  // Member Add
  const [addMemberModal, setAddMemberModal] = useState(false);
  const [member, setMember] = useState({});

  // upuzila filtered
  const [upuzilaList, setUpuzilaList] = useState([]);

  // datas
  const { bloods, districts, sscBatches, upuzilas } = useData();

  return (
    <>
      <div className='pocket-menu'>
        {/* <div onClick={(_) => setAddMemberModal(true)}>Member +</div> */}
        {/* <div>Donor List</div> */}
      </div>

      <Modal
        title='Add Member'
        centered
        visible={addMemberModal}
        onOk={() => {}}
        onCancel={() => {}}
        okText='Add Member'
      >
        <Form layout='vertical'>
          <Form.Item label='Name'>
            <Input placeholder='name' />
          </Form.Item>
          <Form.Item label='Phone'>
            <Input placeholder='name' />
          </Form.Item>
          <Form.Item label='Blood Group'>
            <Select showSearch>
              {bloods.map((blood, i) => (
                <Option key={`group-${i}`} value={blood.value}>
                  {blood.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label='District'>
            <Select
              onChange={(e) => {
                const uzulas = upuzilas
                  .filter((x) => {
                    if (
                      String(x.district).toLowerCase() ===
                      String(e).toLowerCase()
                    ) {
                      return true;
                    }
                    return false;
                  })
                  .map((x) => String(x.upazila).replace("Upazila", ""));
              }}
              showSearch
            >
              {districts.map((dist, i) => (
                <Option key={`dist-${i}`} value={dist.name}>
                  {dist.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label='Upuzila'>
            <Select showSearch>
              {upuzilaList.map((upuzila, i) => (
                <Option key={`upuzila-${i}`} value={upuzila}>
                  {upuzila}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label='Batch'>
            <Select showSearch>
              {/* {sscBatches.map((b, i) => (
                <Option key={`batch-${i}`} value={b.name}>
                  {b.name}
                </Option>
              ))} */}
            </Select>
          </Form.Item>
          <Form.Item label='Password'>
            <Input placeholder='name' />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default PocketMenu;
